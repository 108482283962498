<template>
	<div class="project">
		<div class="main">
			<div class="content">
				<div class="title">
					<img src="@/assets/ques/ques.png"> 法律声明
				</div>
				<div class="law">
					<h3>本网站为海尔集团拥有、管理和维护。使用本网站前请您仔细阅读以下条款，使用本网站则表明您已明知并接受这些条款，如果不接受 这些条款请不要使用本网站。</h3>
					<h3>1、网站使用</h3>
					<p>卡萨帝网站（以下简称“本站”）上的内容，仅供您个人而非商业使用。对于内容中所含的版权和其他所有权声明，您应予以尊重并在其副本中予以保留。如果网站内容无权利声明，并不代表网站对其不享有权利，也不意味着网站不主张权利，您应根据诚信原则尊重该等内容的合法权益并进行合法使用。您不得以任何方式修改、复制、公开展示、公布或分发这些材料或者以其他方式把它们用于任何公开或商业目的。禁止以任何目的把这些材料用于其他任何网站或其他平面媒体或网络计算机环境。本站上的内容及编辑等形式均受版权法等法律保护，任何未经授权的使用都可能构成对版权、商标和其他法律权利的侵犯。如果您不接受或违反上述约定，您使用本站的授权将自动终止，同时您应立即销毁任何已下载或打印好的本站内容。</p>
					<h3>2、信息发布</h3>
					<p>本站的信息按原样提供，不附加任何形式的保证，包括适销性、适合于特定目的或不侵犯知识产权的保证。此外，卡萨帝也不保证本站信息的绝对准确性和绝对完整性。卡萨帝网站中的内容或在这些内容中介绍的产品、价格和配置等会随时变更，恕不另行通知。本站中的内容也可能已经过期，卡萨帝不承诺更新他们。本站发布的信息可能是在您本地尚不能得到的产品、程序或服务，您可向当地的卡萨帝联系人和经销商咨询。</p>
					<h3>3、用户提交材料</h3>
					<p>除个人识别信息，其他任何您发送或邮寄给本站的材料、信息或联系方式(以下统称信息)均将被视为非保密和非专有。卡萨帝将对这些信息不承担任何义务。同时您的提交行为如果没有特别声明时，可视为同意（或授权）：卡萨帝及其授权人将可因商业或非商业的目的自由复制、透露、分发、合并和以其他方式利用这些信息和所有数据、图像、声音、文本及其他内容。您对本站的使用不得违背法律法规及公众道德，不得向或从本站邮寄或发送任何非法、威胁、诽谤、中伤、淫秽、色情或其他可能违法的材料。若相关人对此信息的内容及影响提出确有证据的警告或异议，本站可随时删除该等信息或无限时中止该信息的网上浏览，而不必事先取得提交者的同意，亦无义务事后通知提交者，情况严重的，本站可采取注销该用户的措施。</p>
					<h3>4、用户交流内容</h3>
					<p>卡萨帝不负监控或审查用户在本站上发送或邮寄的信息或相互之间单独交流的任何领域信息的责任，包括但不限于交谈室、公告牌或其他用户论坛以及任何交流内容。卡萨帝对有关任何这类交流的内容不承担任何责任，无论它们是否会引起诽谤、隐私、淫秽或其它方面的问题。卡萨帝保留在发现时删除包含被视为侮辱、诽谤、淫秽或其它不良内容的信息消息的权利。</p>
					<h3>5、软件使用</h3>
					<p>如果您从本站下载软件，在使用软件时要遵守该软件携带的软件许可协议中所有的许可条款。在您阅读并接受软件许可协议的各项条款之前，不得下载或安装这一软件。</p>
					<h3>6、第三方网站</h3>
					<p>本站到第三方网站的链接仅作为一种方便服务提供给您。如果使用这些链接，您将离开本站。卡萨帝对这些网站及其内容不进行控制，也不负任何责任。如果您决定访问任何与本站链接的第三方网站，其可能带来的结果和风险全部由您自己承担。</p>
					<h3>7、责任限度</h3>
					<p>卡萨帝及其供应商或本站中提到的第三方对任何损害概不负责（包括但不限于由损失的利润、损失的数据或业务中断而造成的损害），无论这些损害是否由于使用、不能使用本站的结果、连到本站的任何Web网站或者任何这类网站中包含的信息所引起（包括但不限于由损失的利润、损失的数据或业务中断而造成的损害），也不管它们是否有保证、合同、侵权行为或任何其它法律根据以及事前已得到这类损害可能发生的消息。如果您由于使用本站的信息导致需要对设备或数据进行维护、修理或纠正，您也必须承担由此而造成的所有费用。</p>
					<h3>8、国际用户</h3>
					<p>该网站由卡萨帝青岛总部的办公室进行控制、操作和管理。卡萨帝不保证该网站上的资料适用于或可用于中国之外的其它地点，以及您能够从资料内容被视为非法的区域取阅这些资料。您不得违背中国出口法规使用网站或出口资料。如果您从中国之外的地点访问该网站，则您有责任遵守所有当地法律。这些使用条款和条件受中国法律管辖，且和其法律条文不相抵触。</p>
					<h3>9、更改</h3>
					<p>卡萨帝可能随时修改这些条款，您应经常访问本页面以了解当前的条款，这些条款的某些条文也可能被本站中某些页面上明确指定的法律通告或条款所取代。卡萨帝可随时终止、更改、暂停或中止该网站的任何方面，包括该网站任何特性的可用性。卡萨帝还可未做事先通知且不承担责任对某些特征和服务加以限制或限制您访问网站的某些部分或全部。卡萨帝可终止上述授权、权利和许可，您应在此类终止时立即销毁所有资料。</p>
					<h3>10、因本公告或使用本网站所发生的争议适用中华人民共和国法律。</h3>
					<h3>11、因本公告或使用本网站发生争议，应当协商解决，协商不成的，由青岛市人民法院诉讼解决。</h3>
					<h3>12、本公告的解释权及对本网站使用的解释权归结于卡萨帝网站。</h3>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import DwFilter from '@/components/DwFilter.vue'
	import DwImg from '@/components/DwImg.vue'
	export default {
		name: 'Law',
		components: {
			DwFilter,
			DwImg
		},
		data() {
			return {
				
			}
		},
		methods: {
			
		}
	}
</script>
<style lang="less" scoped type="text/css">
	.project {
		position: relative;
        padding-top: 60px;
		.main {
			/* min-height: 100vh; */
			background: @second-color;
			padding: 10px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
				background-color: #fff;
				padding: 60px;

				.title {
					font-size: 28px;
					color: @font-color;

					img {
						margin-right: 20px;
					}
				}
                .law{
					margin-top: 20px;
					p{
						font-size:13px;
                        color: rgba(159,172,172,1);
						line-height:30px;
					}
					h3{
						 
					}
				}
				
			}

			
		}
	}
</style>
<style lang="less" scoped type="text/css">
	@import url('@/mixin/base.less');
</style>